.frontpage {
  background-color: $color-primary;
  position: relative;
  z-index: 1;
  color: $color-on-primary;
  background-image: url("/assets/img/city_rotate.svg"), $background-image;
  background-size: 100% 140%, auto;
  background-position-y: 5vh, 0;
  background-position-x: 40vw, 0;
  background-attachment: fixed;
  background-repeat: no-repeat;

  .main-container {
    margin: auto;
  }

  header>.row {
    .col {
      &:nth-child(1) {
        text-align: left;
      }

      &:nth-child(2) {
        display: none;
      }
    }

    .actionicon {
      &:hover:not(.disabled) {
        svg {
          color: $color-dark !important;
          opacity: 0.4;
        }
      }
    }

    .icon-navigation {
      color: $color-on-primary;
    }
  }

  // @todo: widecreen layout testen und verbessern 
  &-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: $container-width;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    z-index: 5;

    .frontpage-title {
      // platform title on frontpage should not contain hyphens
      hyphens: none;
      font-family: $font-heading;
      font-weight: 700;
      font-size: 4.5rem;
      line-height: 1.15;
      max-width: 50rem;

      @media screen and (max-width: $sm) {
        font-size: 2.5rem;
        max-width: 18.75rem;
        margin-bottom: 0;
      }
    }

    .frontpage-subtitle {
      font-family: $font-body;
      font-weight: 300;
      font-style: italic;
      font-size: $h1-font-size;
      line-height: 1.3;
      max-width: 40rem;
      margin: 3rem 0;

      @media screen and (max-width: $sm) {
        font-size: 1.625rem;
        max-width: 25rem;
        margin: 1.25rem 0;
      }
    }

    .btn-frontpage {
      line-height: 1.15;
      width: 25rem;
      display: flex;
      padding: 1.125rem 4rem;
      border-radius: 0.75rem;
      flex-direction: column;
      align-items: center;
      font-style: italic;
      font-weight: 400;
      font-size: $font-size-lg;
      color: $color-white;
      transition: 0.2s;
      border: $color-white 2px solid;
      background-color: $color-dark;

      &:hover {
        opacity: 0.9;
      }

      @media screen and (max-width: $sm) {
        max-width: 20.625rem;
        font-size: 1rem;
      }

      .title {
        padding-bottom: 0.75rem;
        font-family: $font-heading;
        font-style: normal;
        font-weight: 600;
        font-size: $h4-font-size;


        @media screen and (max-width: $sm) {
          font-size: 1.25rem;
        }
      }
    }
  }

  .footer-links a:not(col .social-icons) {
    transition: all 0.175s ease-in-out !important;
    border-bottom: 2px solid $color-on-primary !important;
    border-image: none !important;

    &:hover {
      border-bottom-width: 4px !important;
    }
  }

  .faq-icon {
    color: $color-on-primary;
  }
}