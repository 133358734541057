// @todo: Images: you have to style an outer wrapper for next/image: https://github.com/vercel/next.js/discussions/22861

.program-logo {
  margin-right: 0px;
  position: relative !important;
  max-width: 300px;
  max-height: 200px;

  img {
    max-width: inherit;
    max-height: inherit;
  }
}

.challenge-logo {
  width: 100px;
  height: 100px;
  object-fit: contain;
  object-position: center;
}

// image slider, so far only in public project profile
.carousel {
  position: relative;
  border: 1px solid $color-gray-medium;
  width: 100%;
  height: 100%;

  &__arrow {
    background: transparent;
    border: none !important;
    padding: 0.75rem 0.875rem;
  }

  &__controls {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: rgba($white, 0.8);

    .d-flex>*+* {
      border-left: 1px solid $color-gray-medium;
    }
  }

  &__index {
    font-family: $font-heading;
    padding-left: 1.75rem;
    font-weight: 500;
    line-height: 1;
    width: 4.5rem;
  }

  &__item {
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    background: $color-dark;

    img {
      object-fit: contain;
      object-position: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
    }
  }
}

// used in DSGVOContentElement (currently only used in customer clients)
.embedded-content {
  max-width: 100%;

  @media screen and (min-width: $lg) {
    max-width: 50%;
  }
}

/* #region upload */

.fileupload {

  &__container {
    border: 5px dashed $color-gray-light;
    height: 12rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__hidden {
    height: 12rem;
    width: 92%;
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &--drag-active {
    border: 5px solid $color-primary-light;
  }
}

.fileupload-table {
  width: 100%;

  &__title-col {
    position: relative;
    vertical-align: top;
    left: 2.5rem;
  }

  &__category-col {
    position: relative;
    vertical-align: top;
    left: 1.5rem;
  }

  &__file-col {
    position: relative;
    vertical-align: top;
    left: 4rem;
  }
}

/* #endregion upload */