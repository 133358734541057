// bootstrap-classes
.dropdown {

  .btn {
    padding: 0;
    border: none;
    background: none;
    color: inherit;
    transition: all linear 0.125s;

    &:focus-visible {
      @include focus-visible--no-box-shadow;
    }
  }

  .btn:hover,
  button:hover,
  .btn:active {
    background: transparent;
    color: inherit;
    box-shadow: none;
    border: none;
  }
}

.dropdown-menu {
  border: none;
  background-color: $color-primary;
  background-image: $background-image;
  box-shadow: $box-shadow-menu;
  text-align: center;
  margin-right: -12px;
  margin-top: 13px;
  z-index: 10000;

  .dropdown-item {
    font-family: $font-heading;
    color: $color-on-primary;
    border-radius: 0;
    // undo bootstrap style, padding is set in .dropdown-item>*
    padding: 0;

    &:hover {
      background-color: transparent;
    }

    * {
      padding: 1rem 1.4rem;
      display: block !important;
      color: inherit;
      border: none;

      &:hover,
      .active,
      &:not(:disabled):not(.disabled):active {
        background-color: $color-white-80;
        color: $color-dark;
        border: none;
        border-radius: 0;
      }

      &:focus {
        background-color: $color-white-80;
      }
    }
  }
}

.dropdown-menu::before {
  position: absolute;
  content: "";
  top: -13px;
  right: 13px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid $color-primary-gradient;
}