@charset "UTF-8";

/*
Styles, die von Coder benötigt werden/angelegt werden, aber noch nicht von Linda eingearbeitet wurden
*/

/* #region norbert */

// Ziel der Styles: ein PageError-Bereich in der Page sollte quasi null Platz nutzen, wenn keine Errors angezeigt werden
.page-error {
  display: block
}

// tall input field for headlines, usually used without label, e.g. project-title in project profile form
// Kopiert aus forms.scss, wo es nur innerhalb eines card-header aktiv wird
.input-headline {
  width: 100%;

  input {
    width: 50%;
    border: none;
    outline: none;
    font-size: $h3-font-size;
    color: $color-dark;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
}

// Überarbeitung der Task-Card styles, weil:
// task-cards, die allein auf einer Zeile standen, nahmen die ganze Zeile ein, statt sich zu begrenzen.
// TODO @linda: Bitte auf Sinnhaftigkeit prüfen, vor allem die Kombi aus max-width und width erscheint
// seltsam, war aber nötig, um das Ziel zu erreichen, dass die Cards sich beim Zusammenschieben des Screens
// neu anordnen.
// Ziel war auch, dass nicht alle Cards gleiche Größe haben, unabhängig vom Textinhalt
// TODO: prüfen, wie diese Styles sich jetzt auf die Page auswirken, wo die Arbeitspakete erstellt werden:
// denn dort brauchen die Cards jetzt mehr Platz
.task-card {
  max-width: 25rem; // nötig, um eine Karte, die einzeln auf einer Zeile steht und langen Inhalt hat, zu kürzen
  width: 25rem;

  // card with input field for new project tasks
  &-input-card {
    width: 100%;

    form {
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      .mb-3 {
        flex-grow: 2;
      }
    }
  }
}

// zum Ausgrauen von Text, benutzt im Documents-Branch zum Informieren des Users,
// wenn ein Kapitel noch keine Inhalte hat
.grayed {
  color: $color-gray-medium;
}

/* #endregion norbert */

/* #region frieder */

// add hyphens / auto syllabification
body {
  hyphens: auto;
}

.fileupload-table__file-col {
  position: relative;
  vertical-align: top;
  // left: 4rem; <-- sorgte dafür, dass das Div in die Folgespalte ragte und der Download-Button bei manchen Fensterbreiten nicht klickbar war
  padding-left: 5rem;
}

/* #endregion frieder */

/* #region linda */

/* #endregion linda */

/* #region phillipp */

// Alle Filterelemente, z.Bsp. CategoryFilter (Komponente) beinhalten die Navigation sowie
// die Elemente (Kategorien) selbst. Mit Navigation ist gemeint, der Button zum Anzeigen
// bzw. verstecken der Elemente. Dadurch ist es nicht mehr möglich,z.Bsp. im Suchformular 
// vom Projektmarkt, die Navigation unabhängig von den Elementen zu platzieren.
// Um nun die aktuell anzeigenden Elemente unterhalb der Navigationselemente anzuzeigen,
// werden die anzuzeigen Elemente, z.Bsp. alle Kategorien ans Ende gesetzt und es wird
// unabhängig von der Anzahl der Kategorien gesagt, dass sie 100% des Platzes einnehmen
// um das Flexlayout zu zwingen, es in einer neuen Zeile anzuzeigen.
.filtering__collapse-show {
  order: 2; // ans Ende der Zeile setzen
  flex-basis: 100%; // das Element nimmt 100% des Platzes ein
}

// If an input-field like check or switch is not part of a group, the display-property should be of value
// flex and not grid, otherwise will the label not be aligned with the input field.
.form-input--single {
  display: flex;
}

// Using only svg to avid splitting a merged graphic consisting of svg and css.
// TODO: SVGs skalierbar machen: https://futureprojects.atlassian.net/browse/FCP-1325
// @todo: bisher nur Zwischenlösung, funktioniert für Kapitel-Zahlen bis 99, wenn card-header mehrzeilig ist. 
.card-badge {
  &--vertical {
    // Only use display: "flex" in order to center the text. Otherwise padding or margin had no influence
    // to change the position of the text within the badge.
    display: flex;
    align-items: center;
    background-image: url(/assets/img/card-badge/card-badge--vertical.svg);
    background-repeat: no-repeat;
    height: 4rem;
    min-width: 3.5rem;
    // @todo: margin-right wird gelöscht mit Erledigung dieses Tickets:
    // https://futureprojects.atlassian.net/browse/FCP-1881 
    margin-right: 1rem;
    margin-top: -2.7rem;
    justify-content: center; // aligns the text within

    &__text {
      font-family: $font-heading;
      font-size: 1.875rem !important;
      // font-weight: bold;
      margin-top: -1.2rem;
      padding-left: 1.2rem;
      padding-right: 1.2rem;
      white-space: nowrap;
    }
  }
}


// Styles are based on the template and inspired by https://dev.to/themodernweb/checkbox-css-style-gradient-checkbox-with-pure-css-custom-input-css-3gko
.form-switch {

  width: fit-content;

  .form-check-input {
    width: 2.4rem;
    height: 1.5rem;

    background: $color-gray-lighter;
    border: none;

    &:hover:not(:checked, :disabled) {
      background: $color-gray-light;
    }

    &:hover:not(:disabled) {
      cursor: pointer;
    }

    // Add the white circle, which represents the button of the switch.
    &::before {
      content: '';
      display: inline-block;
      width: 100%;
      height: 100%;
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='rgba(255, 255, 255)'/></svg>");
      background-position: left center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &:focus {
      box-shadow: 0 0 0 0.3rem rgba(250, 224, 140, 0.800);

      &:not(:checked) {
        box-shadow: 0 0 0 0.3rem rgba(223, 222, 222, 0.928);
      }
    }

    // Inject the color gradient as a background.
    &:checked {
      background: $background-image;
      border: none;

      &::before {
        background-position: right center;
      }
    }
  }

  .form-check-label {
    padding-left: 0.5rem;
    margin-bottom: 0 !important;
    font-size: 1.2rem;

    &:hover {
      cursor: pointer;
    }
  }

  // Reset the cursor of the label to default, if checkbox is disabled.
  .form-check-input:disabled+.form-check-label:hover {
    cursor: default;
  }
}

// Responsible for making a svg green. Used in the documents page.
.make-green {
  text-wrap: nowrap;
  text-align: center;

  svg {
    color: $green !important;
    // Necessary to define the width and height, b/c the chevron and check icon with text have to be aligned,
    // which means, the borders of the chevron and the check icon have the same height.
    width: 1.563rem;
    height: 1.563rem;
  }
}

// Responsible for making a svg yellow. Used in the documents page.
.make-yellow {
  text-wrap: nowrap;
  text-align: center;

  svg {
    color: $yellow !important;
    // Necessary to define the width and height, b/c the chevron and check icon with text have to be aligned,
    // which means, the borders of the chevron and the check icon have the same height.
    width: 1.563rem;
    height: 1.563rem;
  }
}

// Seite: market/[id]/ideas/[ideasId]/create-project
// Inputfield of the project title should be across the whole width of the card.
// TODO Linda kann das verallgemeinert werden
.create-project__form .card .title-section {
  flex-grow: 1;
}

/* #endregion phillipp */