.task-card {

  form,
  .task-card-view {
    display: flex;
    gap: 1rem;
    align-items: center;

    div:first-of-type {
      flex-grow: 1;
      margin-bottom: 0 !important;
    }
  }

  &-layout {
    display: contents;
  }

  .card-body {
    padding: 1.75rem 0 !important;
  }
}

.task-popover-container .popover {
  width: 30rem;
  max-width: none;
}

.task-card,
.resource-card {

  .icon-navigation {
    display: flex;
    flex-wrap: nowrap;
    gap: 0;
  }

  .input-group-element {
    margin: 0rem;
    margin-left: 0.4rem;
    margin-left: 0.4rem;
  }
}

.work-package-card {

  &__description {
    font-size: $font-size-sm;
    font-style: italic;
    // TODO: color-gray existiert nicht. Stattdessen color-gray-medium?
    color: $color-gray;
    margin: 1.5rem;
  }

  &__name {
    font-weight: bold;
    font-size: 1.125rem;
  }

  .task-card {
    margin: 1rem !important;
  }

  .card-footer {
    background: $color-gray-lighter;

    .icon-navigation {
      margin: 0;
    }
  }

  >.collapse .card-body,
  >.collapsing .card-body {
    padding: 0;
  }
}

// @todo Linda: Prüfen. Dafür mit backend verbinden.
.challenge-concretizations-list {
  li+li {
    margin-top: 2.25rem;
  }
}